.score-banner {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 175px;
  right: 50px;
  color: #174374;
  font-size: 30px;
  .regio {
    background: #F7F8F8;
    padding: 5px 15px;
    border-radius: 12px 12px 0 12px;
    border: 8px solid #CAE0F0;
    font-size: 24px;
  }
  .score-stars {
    position: relative;
    top: -8px;
    background: #F7F8F8;
    padding: 5px 15px;
    border-radius: 0 0 12px 12px;
    border-right: 8px solid #CAE0F0;
    border-bottom: 8px solid #CAE0F0;
    border-left: 8px solid #CAE0F0;
    border-top: 0px solid #F7F8F8;
    width: fit-content;
    .progress-bar {
      position: relative;
      .baseline {
        min-width: 230px;
        height: 21px;
        background: #CAE0F0;
      }
      .progress {
        transition: width .2s;
        position: absolute;
        background: #174374;
        max-width: 230px;
        width: 0%;
        height: 21px;
      }
    }
  }
  .stars{
    position: relative;
    padding-bottom: 21px;
  }
  .star-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    position: absolute;
    top: -21px;
    &:nth-child(1) {
      left: 47%;
    }
    &:nth-child(2) {
      left: 60.5%
    }
    &:nth-child(3) {
      left: 72%;
    }
    .line {
      width:1px;
      height: 21px;
      background: #000;
    }
  }
}
