.welcome {
  position: absolute;
  top: 25px;
  lefT: 25px;
  padding: 25px;
  border: 3px solid #CAE0F0;
  border-radius: 12px;
  .title {
    color: #174374;
    font-size: 20px;
  }
  .rang {
    padding: 10px;
    background: #026FD4;
    color: #fff;
    border-radius: 100px;
    line-height: 1;
    &:hover {
      cursor: pointer;
    }
  }
}