.mode-selection {
h2 {
  font-size: 40px;
  font-weight: bold;
  color: #174374;
  text-transform: uppercase;
  text-align: center;
}
h2 {
  margin: 0 152px 50px;
}
}
.mode-link {
  display: block;
  width: 100%;
  position: relative;
}
.mode-title {
width: 100%;
  display: block;
  text-align: center;
  margin-top: 50px;
  font-size: 32px;
  color: #174374;
  font-weight: bold;
  text-transform: uppercase;
}
.mode-button {
  display: block;
  width: 100%;
}
.mode-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: fit-content;

}