.vooruitgang-button, .generator-button, .score-button, .leaderboard-button, .lb-score-button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  background: #174374;
  padding: 12.5px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 16px solid #0D2F55;
  border-right: 16px solid #0D2F55;
  z-index: 11;
  &:hover {
    cursor: pointer;
  }
  .title {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-left: 10px;
  }
}
.generator-button {
  position: unset !important;
  width: fit-content;
}
.leaderboard-button {
  bottom: 50px !important;
  position: fixed;
  left: unset !important;
}