.difficulty {

  .difficulties {
    margin: 75px auto;
    max-width: 1092px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .one, .two, .three {
      border-radius: 35px;
      position: relative;
      .difficulty-inner {
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        img {
          max-width:80px;
        }

        h2 {
          font-size: 30px;
          font-weight: bold;
        }
        .title {
          margin-bottom: 50px;
        }
        .line {
          width: 169px;
          height: 7px;
        }

        .description {
          font-size: 20px;
          font-weight: bold;
        }

        .high-score, .goal-score {
          min-width: 190px;
          min-height: 108px;
          display: flex;
          flex-direction: column;
          padding: 15px;
          font-size: 25px;
          background: #fff;
          border-radius: 20px;
          text-align: center;
          font-weight: bold;
          color: #000;
        }
        .stars {
          .stars-inner {
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
          }

        }
      }
    }
    .one, .two, .three {
      margin: 17px 0;
    }
    .one {
      background: #F9E11E;
      border-bottom: 16px solid #FFC536;
      border-right: 16px solid #FFC536;
      .line {
        background: #000;
      }
    }

    .two {
      background: #026FD4;
      border-bottom: 16px solid #00529D;
      border-right: 16px solid #00529D;
      .line {
        background: #fff;
      }
      .title, .description {
        color: #fff;
      }
    }
    .locked {
      * {
        &:hover {
          cursor: initial;
        }
      }
      .content {
        opacity: .2;
      }
    }
    .three {
      background: #174374;
      border-bottom: 16px solid #174374;
      border-right: 16px solid #174374;
      .line {
        background: #fff;
      }
      .title, .description {
        color: #fff;
      }
    }
  }
}
.stars {
  .stars-inner {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

}