.score-page {
  position: relative;
  display: flex;
  min-height: 100vh;
}
.score-content {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .user-stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 25px;
  }
  .user-info {
    margin: 0 25px;
    padding: 15px;
    border: 4px solid #CAE0F0;
    border-radius: 5px;
    text-align: center;
    min-width: 476px;
    font-size: 30px;
    color: #174374;
  }
  .played-before {

    h1 {
      margin-bottom: 25px;
    }
    .played {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      padding: 20px;
      width: 1037px;
      margin:15px auto;
      font-size: 20px;
      color: #174374;
      .title {
        min-width: 550px;
      }
      .ranking-container {
        width: 100px;
        text-align: center;

        .ranking {
          padding: 20px;
          background: #026FD4;
          border-radius:  50%;
          font-weight: bold;
          color: #fff;
          min-width: 60px;
          min-height: 60px;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .score {
        width: 100px;
        text-align: right;
      }
      .stars {
        width: 125px;
        justify-content: center;
      }
      .replay-button-container {
        width: 80px;
      }
    }

    .stars {
      display: flex;
    }
    .replay-button-container {
      img {
        max-width: 80px;
      }
    }
    .replay-button {

      &:hover {
        cursor: pointer;
      }
    }
  }
}
.m-result-replay-button {
  background: #174374;
  padding: 12.5px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 16px solid #0D2F55;
  border-right: 16px solid #0D2F55;
  z-index: 11;
  .title {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-left: 10px;
  }
}
