.form-login {
  max-width: 1240px;
  margin: 0 auto;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
input {
  padding: 10px;
  margin: 5px 0;
  min-width: 250px;
  border-radius: 3px;
}
}