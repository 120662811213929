.hamburger {
  text-transform: uppercase;
  overflow: hidden;
  position: absolute;
  right: 50px;
  top: 50px;
  transition: .3s all;
  .audio {
    display: none;
    .switch {
      right: unset;
      left: 0;
    }
  }
  .burger-slices {
    padding: 15px;
    background: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 4px solid #CAE0F0;
    border-right: 4px solid #CAE0F0;
    transition: .3s all;
    -moz-transition: .3s all;
    -webkit-transition: .3s all;
    width: 64px;
    height: 61px;

    &:hover {
      cursor: pointer;
    }
  }

  .line {
    width: 30px;
    height: 5px;
    background: #174374;
    display: block;
    margin: 2px 0;
  }

  .line-1, .line-3 {
    transition: all .2s;
    -moz-transition: .2s all;
    -webkit-transition: .2s all;
  }

  .hamburger-inner {
    opacity: 0;
    position: absolute;
    transition-delay: 1s;
    transition: opacity .2s;
    -moz-transition: .2s opacity;
    -webkit-transition: .2s opacity;
    visibility: hidden;
  }
}

.expand-width {
  .burger-slices {
    width: 323px !important;
    height: 323px;

    .line-1 {
      transform: rotate(45deg);
      transition: all .2s;
      position: relative;
      top: 8px;
    }

    .line-2 {
      visibility: hidden;
      position: absolute;
      transition: all .0s;
    }

    .line-3 {
      transform: rotate(-45deg);
      transition: all .2s;
    }
  }

  .hamburger-inner {
    z-index: 5;
    opacity: 1 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    visibility: visible;
    transition-delay: .21s;

    li {
      font-size: 30px;
      color: #174374;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .audio {
    .switch {
      right: -50px !important;
      left: unset !important;

    }
  }

}

.hamburger-closed {

  position: absolute;
  right: 50px;
  top: 100px;

  .burger-slices {
    padding: 15px;
    background: #174374;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 4px solid #0D2F55;
    border-right: 4px solid #0D2F55;
    transition: .3s all;
    width: 64px;
    height: 61px;

    .line-1 {
      transform: rotate(45deg);
      transition: all .2s;
      position: relative;
      top: 8px;
    }

    .line-2 {
      visibility: hidden;
      position: absolute;
      transition: all .0s;
    }

    .line-3 {
      transform: rotate(-45deg);
      transition: all .2s;
    }
  }

  .line {
    width: 30px;
    height: 5px;
    background: #fff;
    display: block;
    margin: 2px 0;
  }

}
.audio {
  display: flex;
  align-items: center;
}
.switch {
  position: absolute;

  width: 97px;
  height: 40px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 4px solid #174374;
    border-radius: 8px;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 40px;
    left: 4px;
    bottom: 4px;
    border-radius: 8px;
    background-color: #174374;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #fff;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #fff;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(39px);
    -ms-transform: translateX(39px);
    transform: translateX(39px);
  }
}