.closed {
  display: none;
}
.explain-popup {
  position: absolute;
}
.popup {
  position: absolute;
  .tools {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top:25px;
    right:35px;
    &:hover {
      cursor: pointer;
    }
  }
  .dont-show-tools {
    display: none;
  }
}
.explanationPopup {
  width: 1100px;
  height:750px;
  background: #fff;
  padding: 50px;
  margin: 0 auto;
  top: 50px;
  border-bottom: 12px solid #CAE0F0;
  border-right: 12px solid #CAE0F0;
  border-radius: 15px;
  h2 {
    color:#174374;
    font-weight: bold;
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  img {
    width: 207px;
  }


  .visual-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    .left {
      min-width: 207px;
      img {
        width: 207px;
      }
    }
  }
  .visual-full {
    img {
      width: 100%;
    }
  }
}