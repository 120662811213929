.previous-button {
  position: absolute;
  right: 50px;
  top: 50px;
  transition: .3s all;
  z-index: 5;
  .button-inner {
    padding: 15px;
    background: #fff;
    border-radius: 15px;
    width: 64px;
    height: 61px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    border-bottom: 4px solid #CAE0F0;
    border-right: 4px solid #CAE0F0;
    &:hover {
      cursor: pointer;
    }
  }
}