#total-score {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  max-width: 875px;
  margin: 0 auto;
}

.mission-result {
  background: #f7f8f8;
  padding-bottom: 50px;

  .main-content {
    max-width: 1240px;
  }
}

.mission-result {
  .mission-r-previous-button {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 20;

    .previous-button {
      position: unset !important;
    }
  }
}

.mission-results-header {

  padding: 25px 0;
  display: block;
  margin: 0 auto;
  max-width: 940px;
  h1 {
    line-height: 1;
    span{
      font-size: 20px;

    }
  }
}

#mission-results {
  justify-content: start;
}

.result-item {
  width: 600px;
  margin: 10px;
  padding: 70px 0;
  border-radius: 8px;
  border: 10px solid #D7D7D7;

  .score-text {
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
    color: #174374;
  }

  .questionSign {
    min-width: 230px !important;
    max-width: 230px !important;
    height: 100px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 12px;
      text-align: left;
      word-break: normal;
    }

    .title-small {
      font-size: 12px;
      text-align: left;
      word-break: normal;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    max-width: 100px;
  }

  .longblue, .longgray {
    min-width: 230px !important;
    max-width: 230px !important;
    height: 100px;
    width: 100%;
    padding: 0px;
    word-break: break-word;

    .image {
      margin-right: 0;
    }

    .title {
      font-size: 12px;
      word-break: normal;
    }

    img {
      width: 80px;
      height: 50px;
      max-width: unset;
      margin-right: 0px;
    }
  }

  .square-gray, .square-blue {
    min-width: 230px !important;
    max-width: 230px !important;
    width: 100%;
    height: 100px;
    padding: 10px;
    flex-direction: row;
    word-break: break-word;

    .image {
      margin-right: 0px;
    }

    img {
      width: 80px;
      height: 50px;
      margin-right: 0px;
    }

    .title {
      font-size: 12px;
      word-break: normal;
    }
  }

  .square-gray {
    padding: 10px;

    .image {
      margin: 0;
    }

    img {
      height: 50px;
      max-width: unset;
    }

    .title {
      font-size: 12px;
      word-break: normal;
    }
  }

  .km-mark {
    width: 100%;
    height: 100px;
    padding: 5px !important;
    justify-content: center;
    word-break: break-word;

    .image {
      margin-right: 0px;
    }

    .km-title {
      width: fit-content;
      padding: 0 20px;
      font-size: 12px;
      word-break: normal;
    }
  }
}