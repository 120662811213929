.closed {
 display: none; 
}
.tips-tools {
  position: relative !important;
  top:50px !important;
  z-index: 1;
}
.dont-show-tools {
  display: none;
}
.closePopup {
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
}
.tips-explain {
  margin: 50px auto;
  max-width: 1100px;
}
.tips-popup {
  z-index: 100;
  .tools {
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top:25px;
    right:35px;
    &:hover {
      cursor: pointer;
    }
  }
  .dont-show-tools {
    display: none;
  }
}
.tip-explain-buttons {
  bottom: 100px !important;
}
.tips-page {
  display: block;
  z-index: 100;
  position: fixed;
  top:0;
  width: 100%;
  height:100%;
  background: #F7F8F8;
  .illustration-bottom {
    left: 0;
  }
}
.tips {
  max-width: 1040px;
  margin: 50px auto;
  height:100%;
  p {
    font-weight: 500;
    font-size: 20px;
    color: #184475;
    text-align: center;
  }
}
.bottom-stats {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  position: absolute;
  bottom: 50px;
  right: 100px;
  .title {
    font-size: 24px;
    color: #174374;
  }
  .number {
    padding: 15px;
    background: #026FD4;
    border-radius: 100px;
    line-height: .4;
    font-weight: bold;
    color: #fff;
  }
  .stars {
    display: flex;
    flex-direction: row;
    .star-gold,.star-gray {
      margin: 0 2.5px;
    }
  }
  .replay-button {
    background: #fff;
    padding: 10px;
    border-radius: 100px;
    border-bottom: 12px solid #CAE0F0;
    border-right: 12px solid #CAE0F0;
    &:hover {
      cursor: pointer;
    }
  }
}