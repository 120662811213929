.mission-closed-button {
  z-index: 1;
  .burger-slices {
    background: #F63A3A !important;
    border-bottom: 4px solid #bb2020 !important;
    border-right: 4px solid #bb2020 !important;
  }
  &:hover {
    cursor: pointer;
  }
}
.exit {
  top: 25px;
  position: absolute;
  right: 25px;
  &:hover {
    cursor: pointer;
  }
}
.close-game-closed {
  display: none;
}
.close-game-popup {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(23, 67, 116, 0.9);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-inner {
    position: relative;
    background: #fff;
    border-bottom: 8px solid #CAE0F0;
    border-right: 8px solid #CAE0F0;
    min-width: 720px;
    display: flex;
    min-height: 312px;
    text-align: center;
    line-height: 1;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    .line {
      width: 30px;
      height: 5px;
      background: #174374;
      display: block;
      margin: 2px 0;
    }
    .line-1 {
      transform: rotateX(45deg);
    }
    .line-2 {
      transform: rotateX(90deg);
    }

    p {
      font-size: 28px;
      color: #174374;
      font-weight: 500;
    }
    .buttons {
      margin-top: 50px;
      button {
        text-align: center;
        padding: 25px;
        background:#174374;
        border-radius: 35px;
        color: #fff;
        min-width: 162px;
        font-size:30px;
        font-weight: bold;
        margin: 0 15px;
        border-bottom: 12px solid #0D2F55;
        border-right:12px solid #0D2F55;
      }
    }
  }
}