.doel-card {
  position: absolute;
  top: 80px;
  left: 0;
  background-color: #fff;
  padding: 25px 50px;
  border-radius: 0 15px 15px 0;
  border: 5px solid #CAE0F0;
  border-left: none;
  max-width: 367px;
  width: 100%;
  .longblue, .longgray, .square-gray {
    min-width: unset !important;
    font-size: 16px;
    word-break: break-word;
  }
  .title {
    display: flex;
    align-items: flex-end;
    word-break: normal;
    margin:5px 0;
  }
  h2 {
    font-size: 30px;
    color: #174374;
  }
  img.pin {
    max-width: 21px;
    height: 100%;
  }
  .line {
    width: 100%;
    height: 4px;
    background: #174374;
    border-radius: 10px;
    margin: 20px 0;
  }
}
.showing-answer {

}
