.longblue {
  padding: 25px;
  background: #026FD4;
  border: 9px solid #fff;
  min-width: 368px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  .image {
    margin-right: 10px;
    min-width: 50px;
  }
  .title {
    color: #fff;
  }
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
.longgray {
  padding: 25px;
  background: #F7F8F8;
  border: 8px solid #C1DBEE;
  min-width: 368px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  .image {
    margin-right: 10px;
    min-width: 50px;
  }
  .title {
    color: #000;
  }
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
.square-gray {
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  background: #fff;
  border: 9px solid #CAE0F0;
  max-width: 230px;
  .image {
    min-width: 50px;
    img {
      width: 50px;
      height: 50px;
    }
    //margin-right: 10px;
  }
  .title {
    color: #000;
  }
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
.square-blue {
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  background: #026FD4;
  border: 9px solid #fff;
  max-width: 230px;
  .image {
    min-width: 50px;
    img {
      width: 50px;
      height: 50px;
    }
    margin-right: 10px;
  }
  .title {
    color: #fff;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 16px;
  }
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

.km-mark {
  padding: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  background: #026FD4;
  border: 9px solid #fff;
  max-width: 250px;
  .image {
    margin-right: 10px;
    min-width: 50px;
    img {
      width: 50px;
    }
  }
  .title {
    color: #fff;
  }
  .km-title {
    background: #F7F8F8;
    border-radius: 5px;
    border: 3.55px solid #CAE0F0;
    font-weight: bolder;
    color: #000;
    padding: 0 20px;
  }
}
