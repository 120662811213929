.leaderboard-page {
  position: relative;
  display: block;
  min-height: 100%;
}

.category-titles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
  align-items: center;
  font-size: 20px;
  border-radius: 12px;
  padding: 10px 25px;

  .ranking-container {
    width: 100px;
  }

  .name {
    width: 230px;
    margin-right: 60px;
  }

  .title {
    min-width: 200px;
    margin-right: 20px;
    text-align: center;
    //margin: 0 120px 0px 0px;
  }
}

.scores {
  display: flex;
  align-items: center;
  min-width: 500px;
}

.leaderboard-droog {
  .category-titles {
    border-bottom: solid 4px #174374;
  }
}

.leaderboard-nat {
  .category-titles {
    border-bottom: solid 4px #026FD4;
  }
}

.leaderboard-droog, .leaderboard-nat {
  max-width: 1032px;
  width: 100%;
  margin: 0 auto;
  border-radius: 0 20px 20px 20px;
  min-height: 500px;
  padding: 25px;
  background-size: 100%;
  background: #174374 no-repeat 0px -110px;

  h2 {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 32px;
    margin: 0 0 15px 0;
  }

  .lb-inner {
    background: #fff;
    padding: 25px;
    border-radius: 20px;
  }

  .score-content {
    margin: unset;

    .played {
      width: 926px;
      background: unset !important;
      justify-content: flex-start;
      border-radius: 12px;
      padding: 10px 25px;

      font-size: 20px !important;
      .number {
        background: #174374;

      }

      .number, .name {
        margin-right: 60px;
      }

      .name {
        width: 230px;
      }

      .score {
        padding: 0 35px;
        border: 8px solid #CAE0F0;
        min-width: 200px;
        text-align: center;
        border-radius: 5px;
        background: #fff;
        color: #174374;
        margin-right: 20px;
      }

      .stars {
        border: 8px solid #CAE0F0;
        border-radius: 5px;
        padding: 2px 20px;
        background: #fff;
        color: #174374;
      }
    }

    .played-active {
      background: #174374 !important;
      color: #fff !important;
      font-weight: bold;
      border-radius: 12px;

      .name {
        color: #fff !important;
      }

      .ranking {
        background: #fff;
        color: #174374
      }
    }
  }
}

.leaderboard-nat {
  background-color: #026FD4;
  background-size: 110%;
  background-position: 0px -60px;

  .lb-circle {
    background: #026FD4 !important;
  }

  .score-content {
    .played {
      .number {
        background: #026FD4;
      }
    }

    .played-active {
      background: #026FD4 !important;
      color: #fff;
      font-weight: bold;
      border-radius: 12px;

      .number {
        background: #fff;
        color: #026FD4
      }

      .name {
        color: #fff !important;
      }
    }
  }
}

.leaderboard-buttons {
  max-width: 1032px;
  width: 100%;
  margin: 100px auto;

  .droog-button {
    background: #174374;
    color: #fff;
  }

  button {
    padding: 10px 40px;
    border-radius: 15px 15px 0 0;
    font-size: 30px;
    font-weight: bold;
  }

  .nat-button {
    background: #026FD4;
    color: #fff;
  }
}
