.support {
  font-size: 20px;
  p {
    max-width: 750px;
    margin: 25px auto;
    a {
      color: #026FD4;
    }
  }
}.next-button {
   width: fit-content;
   bottom:50px;
   right: 50px;
   position: absolute;
  margin: 0 15px;
   background: #F9E11E;
   padding: 12px 35px;
   border-radius: 35px;
   display: flex;
   align-items: center;
   flex-direction: row;
   border-bottom: 16px solid #FFC536;
   border-right: 16px solid #FFC536;
   &:hover {
     cursor: pointer;
   }
   svg {
     max-width: 51px;
   }
   .title {
     font-size: 30px;
     color: #000;
     font-weight: bold;
     margin-left: 10px;
   }
 }
 .button-blue {
   background: #174374;
   border-bottom: 16px solid #0D2F55;
   border-right: 16px solid #0D2F55;
   .title {
     color: #fff;
   }
 }
 .explain-buttons {
   display: flex;
   position: absolute;
   flex-direction: row-reverse;
   right: 50px;
   bottom: 25px;
   z-index: 2;
 }