.logo {
  @media (min-width: 768px) {
    width: 586px
  }
}
.start-bg {
  background: #fff;
}
.droog-shape, .nat-shape {
  display: none;
  @media (min-width: 1400px) {
    display: inherit;
  }
}
.title-link {
  color: #026FD4 !important;
  font-weight: bold;
  text-underline: #026FD4 !important;
  text-decoration: underline;
}
.droog-shape {
  position: absolute;
  bottom: 0;
}
.nat-shape {
  position: absolute;
  right: 0;
  top: 0;
}
.circle-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  display: none;
  @media (min-width: 768px ) {
    display: inherit;
    border: solid 10px #FFC536;
    border-radius: 100%;
    position: relative;
    width: 300px;
    height: 300px;
    -webkit-animation: pulse 3s ease-out;
    -moz-animation: pulse 3s ease-out;
    animation: pulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}
@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
    opacity: 0.0;
  }
  25% {
    -moz-transform: scale(1.25);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(1.5);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(1.75);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(2);
    opacity: 0.0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
  1% {
    -webkit-transform: scale(1.1);
    opacity: 0.9;
  }
  99% {
    -webkit-transform: scale(1.9);
    opacity: 0.01;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0.0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.0;
  }
  1% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  99% {
    transform: scale(1.9);
    opacity: 0.01;
  }
  100% {
    transform: scale(2);
    opacity: 0.0;
  }
}
.start-title {
  text-transform: uppercase;
  font-size: 50px;
  font-weight: bold;
}
.start-button {
  width: 371px;
  height: 371px;
}
.overheid-logo {
  position: absolute;
  top: 0;
  padding-left:220px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%;
  align-items: center;
}
