.brug {
  border: 8px solid #CAE0F0;
  background: #F7F8F8;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 6px;
  padding: 15px;
  margin: 5px 0;
  svg, img {
    width: 50px;
    height: 50px;
  }
  .titles {
    margin: 0 15px;
  }
  .title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
  }
  .title-small {
    font-size: 12px;
  }
}
.tunnel {
  padding: 25px;
  border-radius: 6px;
  background: #026FD4;
  display: flex;
  align-items: center;
  flex-direction: column;
  .titles {
    margin: 15px 0;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
  }
  .title-small {
    font-size: 16px;
    margin-top: 10px;
  }
}
.knooppunt, .brug, .tunnel {
  width: 100%;
  height: 100%;
  word-break: break-word;
}
.knooppunt {
  padding: 25px;
  border-radius: 6px;
  background: #026FD4;
  display: flex;
  align-items: center;
  flex-direction: column;
  .titles {
    margin: 15px 0;
  }
  .title {
    font-size:16px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
  }
  .title-small {
    font-size: 16px;
    margin-top: 10px;
  }
}
#mission-results {
  .knooppunt {
    svg, img {
      width: 50px;
      height: 50px;
    }
    flex-direction: row;
    .title {
      margin-left: 5px;
    }
    .image {
      padding: 5px;
    }
  }
  .tunnel {
    flex-direction: row;
    word-break: break-word;
    svg {
      width: 50px;
      height: 50px;
    }
    .title {
      margin-left: 5px;
    }
    .image {
      padding: 5px;
    }
  }
}
