.uitleg {
  position: relative;
  max-width:1100px;
  margin: 125px auto;
  background-color: rgba(23, 67, 116, 0.9);
  padding: 25px;
  border-radius: 12px;
  h1,h2,p {
    color: #fff;
    margin: 0;
  }
  p, .sub-heading {
    font-weight: 500;
    font-size: 20px;
  }
  .sub-heading {
    color: rgba(255, 255, 255, 0.57);
    font-size: 30px;
  }
  p {
    margin-top: 25px;
  }
}
.bottom-button {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  border-radius: 12px;
  background: #fff;
  border: 8px solid #CAE0F0;
  position: absolute;
  bottom: 50px;
  left: 50px;
  svg {
    margin-right: 15px;
    max-width: 73px;
  }
}