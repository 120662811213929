$background: #F7F8F8;
body {
  background: $background;
}

.game-container {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-color: $background;
}

.illustration, .illustration-top {
  right: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}

.illustration, .illustration-bottom, .illustration-top {
  @media (min-width: 1600px) {
    display: inherit;
  }
  display: none;
}

.illustration-bottom {
  position: absolute;
  bottom: 0;

  svg {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  z-index: 0;
}

.main-content {
  z-index: 100;
}

.heading {
  font-size: 40px;
  font-weight: bold;
  color: #174374;
  text-transform: uppercase;
  text-align: center;
}

input.sub-heading {
  font-size: 20px;
}

.start-button-map {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #eee;
  z-index: 100;
}

.remove-start-button {
  display: none;
}

.support {
  position: absolute;
  top: 100px;
  width: 100%;

  h1 {
    font-size: 40px;
    font-weight: bold;
    color: #174374;
    text-align: center;
    margin-bottom: 100px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 30px;
    font-weight: normal;
    color: #174374;
    text-align: center;
  }

  a {
    color: #026FD4;
    font-weight: bold;
    text-decoration: underline;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.regions-selection-map {
  .mapboxgl-map {
    margin: 25px auto;
  }

  .mapboxgl-ctrl-attrib-inner, .mapboxgl-ctrl-logo {
    display: none;
  }
}

.background {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.replay-button, .play-next-button, .locked-button {
  background: #fff;
  padding: 10px;
  height: fit-content;
  border-radius: 100px;
  border-bottom: 12px solid #CAE0F0;
  border-right: 12px solid #CAE0F0;

  &:hover {
    cursor: pointer;
  }
}

.play-next-button {
  background: #F9E11E;
  border-bottom: 12px solid #FFC536;
  border-right: 12px solid #FFC536;
}

.locked-button {
  background: #9E9E9E;
  border-bottom: 12px solid #787878;
  border-right: 12px solid #787878;
}

.new {
  position: absolute;
  line-height: 1;
  top: -25px;
  background: #DE3A3A;
  padding: 5px 10px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 30px;
  border: 4px solid #fff;
  color: #fff;
  text-transform: uppercase;
  width: fit-content;
}

.triple-line {
  position: relative;
  top: -5px;
  transform: rotateY(180deg);
}

.score-cards {
  display: flex;

  .small-score-card {
    height: fit-content;
  }

  .big-score-card, .small-score-card {
    margin: 0 15px;
    display: block;
    padding: 20px;
    border: 4px solid #174374;
    border-radius: 20px;
  }
}

.progress-bar-result {
  position: relative;
  margin: 5px 0;

  .baseline {
    min-width: 163px;
    height: 12px;
    background: #C4C4C4;
  }

  .progress {
    transition: width .2s;
    position: absolute;
    background: #248D4C;
    max-width: 100%;
    width: 0;
    height: 12px;
  }
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  background: transparent !important;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none !important;
}

.mapboxgl-ctrl-group {
  button.mapboxgl-ctrl-zoom-out {
    height: 66px !important;
  }
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  position: relative;
  right: 40px;
  top: -150px;
  .mapboxgl-ctrl-attrib-inner {
    position: relative;
    top: 135px;
  }
}

.mapboxgl-ctrl-group button {
  width: 75px !important;
  height: 75px !important;
  background: transparent !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("./img/zoom-icon.svg") !important;
  background-repeat: no-repeat;
  padding: 10px !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("./img/zoom-out.svg") !important;
  background-repeat: no-repeat;
  padding: 10px !important;
}

.mapboxgl-ctrl-group button + button {
  border-top: none !important;
  position: relative;
  top: -2px;
  left: -2px;
}

.mapboxgl-ctrl-group:not(:empty) {
  position: relative;

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 70px;
    height: 137px;
    left: 15px;
    top: 2px;
    border-radius: 25px;
    background: rgb(202, 224, 240);
  }
}

