.info-page {
  .paragraph {
    max-width: 1040px;
    margin: 50px auto;
    font-weight: 500;
    font-size: 20px;
    color: #184475;
    text-align: center;
  }

  .credits {
    background: #F7F8F8;
    padding-bottom: 50px;
    .credit-persons {
      max-width: 1040px;
      margin: 50px auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .column-big {
    border-radius: 10px;
    max-width: 589px;
    min-height: 589px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 6px solid #CAE0F0;
    padding: 25px;
    background-color:#F7F8F8;
    img {
      max-width: 290px;
      margin: 0 auto;
    }
    .person {
      margin: 25px 0;
    }
  }
  .column-small {
    border-radius: 10px;
    width: 100%;
    max-width: 368px;
    min-height: 344px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 6px solid #CAE0F0;
    padding: 25px;
    background-color:#F7F8F8;
    img {
      max-width: 290px;
    }
  }
  .person {
    display: flex;
    align-items: center;
    img {
      max-width: 90px;
      margin: 0 10px 0 0;
    }
    .name, .function {
      font-size: 25px;
      font-weight: bold;
      color: #000;
    }
    .function {
      color: #026FD4;
    }
  }
}