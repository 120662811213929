.name-generator {
  max-width: 1100px;
  margin: 0 auto;
  h3 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    margin-top: 50px;
  }
  .input, .generator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .name-input {
    font-size: 30px;
    margin: 25px 0;
    padding: 25px 65px;
    border: 8px solid #CAE0F0;
    border-radius: 12px;
    background: #F7F8F8;
    min-width: 450px;
    text-align: center;
  }
}
.row-button {
  width: fit-content;
  bottom:50px;
  right: 50px;
  position: unset !important;
}
